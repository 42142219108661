import * as amplitude from "@amplitude/analytics-browser";
import { datadogLogs } from "@datadog/browser-logs";
import { useHandler } from "@redotech/react-util/hook";
import { AuthContext } from "@redotech/redo-customer-portal-app/contexts/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { SettingsContext } from "./settings";

export interface Card {
  id: string;
  card_company: string;
  last_four_digits: string;
  expiration_date: string;
}

export interface User {
  email: string;
  name: string;
  customerId?: string;
  guestEmail?: string;
  guestName?: string;
  address?: string;
  instantRefund?: {
    astraUserId: string;
    card: Card;
  };
}

const NAME_TOKEN_KEY = "redo.user_name_token";
const EMAIL_TOKEN_KEY = "redo.email_token";
const GUEST_NAME_TOKEN_KEY = "redo.guest_name_token";
const GUEST_EMAIL_TOKEN_KEY = "redo.guest_email_token";
const ADDRESS_TOKEN_KEY = "redo.address_token";
const CUSTOMER_ID_KEY = "redo.customer_id_token";

export const UserContext = createContext<User | undefined>(undefined);

export const SetUserContext = createContext<
  ((user: User | undefined) => void) | undefined
>(undefined);

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [user, setUserState] = useState<User | undefined>(undefined);
  const setUser = useHandler((value: User | undefined) => {
    if (user) {
      amplitude.reset();
    }
    if (value) {
      if (process.env.AMPLITUDE_API_KEY) {
        amplitude.init(process.env.AMPLITUDE_API_KEY, {
          defaultTracking: true,
          userId: value.guestEmail || value.email,
        });
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set("storeUrl", window.location.hostname);
        amplitude.identify(identifyEvent);
      }

      datadogLogs.setUser({ email: value.guestEmail || value.email });
    }
    setUserState(value);
  });
  const auth = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const storeURL: string | undefined = settings?.storeUrl;

  useEffect(() => {
    if (user && storeURL) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set("storeURL", storeURL);
      amplitude.identify(identifyEvent);
      datadogLogs.setGlobalContextProperty("storeURL", storeURL);
    }
  }, [user, storeURL]);

  useLayoutEffect(() => {
    if (auth) {
      setUser({
        name: localStorage.getItem(NAME_TOKEN_KEY) ?? "",
        email: localStorage.getItem(EMAIL_TOKEN_KEY) ?? "",
        guestEmail: localStorage.getItem(GUEST_EMAIL_TOKEN_KEY) ?? undefined,
        guestName: localStorage.getItem(GUEST_NAME_TOKEN_KEY) ?? undefined,
        address: localStorage.getItem(ADDRESS_TOKEN_KEY) ?? undefined,
        customerId: localStorage.getItem(CUSTOMER_ID_KEY) ?? undefined,
      });
    } else {
      setUser(undefined);
    }
  }, [auth, setUser]);

  const setUser_ = useHandler((user: User | undefined) => {
    setUser(user);
    if (user) {
      localStorage.setItem(NAME_TOKEN_KEY, user.name);
      localStorage.setItem(EMAIL_TOKEN_KEY, user.email);
      user.guestEmail
        ? localStorage.setItem(GUEST_EMAIL_TOKEN_KEY, user.guestEmail)
        : localStorage.removeItem(GUEST_EMAIL_TOKEN_KEY);
      user.guestName
        ? localStorage.setItem(GUEST_NAME_TOKEN_KEY, user.guestName)
        : localStorage.removeItem(GUEST_NAME_TOKEN_KEY);
      user.address
        ? localStorage.setItem(ADDRESS_TOKEN_KEY, user.address)
        : localStorage.removeItem(ADDRESS_TOKEN_KEY);
      user.customerId
        ? localStorage.setItem(CUSTOMER_ID_KEY, user.customerId)
        : localStorage.removeItem(CUSTOMER_ID_KEY);
    } else {
      localStorage.removeItem(NAME_TOKEN_KEY);
      localStorage.removeItem(EMAIL_TOKEN_KEY);
      localStorage.removeItem(GUEST_EMAIL_TOKEN_KEY);
      localStorage.removeItem(GUEST_NAME_TOKEN_KEY);
      localStorage.removeItem(ADDRESS_TOKEN_KEY);
      localStorage.removeItem(CUSTOMER_ID_KEY);
    }
  });

  return (
    <UserContext.Provider value={user}>
      <SetUserContext.Provider value={setUser_}>
        {children}
      </SetUserContext.Provider>
    </UserContext.Provider>
  );
}
